
<template>
  <customer-table
    apiname="customer"
    idname="customerId"
    :view="view"
    :viewform="viewform"
    :init="init"
    :filednum="3"
    labelname="ข้อมูลลูกค้า"
  ></customer-table>
</template>

<script>
import CustomerTable from "./Components/CustomerTable.vue";
export default {
  data() {
    return {
      view: [
        "customerId",
        "name",
        "address",
        "phone",
        "state",
        "code",
        "lastOrder",
        "updateDateTime",
        "updateBy"
      ],
      viewform: [
        { name: "name", label: "ชื่อลูกค้า", form: "text" },
        { name: "address", label: "ที่อยู่", form: "textarea" },
        { name: "phone", label: "เบอรโทร", form: "text" },
        { name: "state", label: "สถานะ", form: "switch" }
      ],
      init: {
        customerId: "",
        code: "",
        name: "",
        address: "",
        phone: "",
        lastOrder: "",
        state: true,
        updateDateTime: new Date(),
        updateBy: ""
      }
    };
  },
  components: {
    CustomerTable
  }
};
</script>

