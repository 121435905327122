<template>
  <vs-card>
    <!-- begin modal -->
    <vs-popup
      style="width: 100% !important"
      class="custom-popup"
      :title="customerModal.label"
      :active.sync="customerModal.active"
    >
      <vs-row
        style="font-weight:bold; margin-top:30px;"
        class="row-form"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
          vs-xs="12"
          class="form-label px-2"
        >
          <div class="grid grid-cols-6 mb-6">
            <div class="col-span-2 flex justify-end items-center pr-4">
              ชื่อลูกค้า
            </div>
            <div class="col-span-4">
              <vs-input v-model="modalFields.name" />
            </div>
            <div class="col-span-6 mt-6"></div>
            <div class="col-span-2 flex justify-end items-center pr-4">
              ที่อยู่
            </div>
            <div class="col-span-4">
              <vs-textarea v-model="modalFields.address"></vs-textarea>
            </div>
            <div class="col-span-6 mt-2"></div>
            <div class="col-span-2 flex justify-end items-center pr-4">
              เบอร์โทร
            </div>
            <div class="col-span-4">
              <vs-input v-model="modalFields.phone" />
            </div>
            <div class="col-span-6 mt-6"></div>
            <div
              class="col-span-2 flex justify-end items-center pr-4"
              v-if="customerModal.mode === 'edit'"
            >
              <font-awesome-icon icon="coins" class="mr-2" />
              แต้มคะแนน
            </div>
            <div class="col-span-4">
              <span class="text-black text-xl" v-text="modalFields.totalPoints"
                >0</span
              >
            </div>
            <div class="col-span-6 mt-6"></div>
            <div class="col-span-2 flex justify-end items-center pr-4"></div>
            <div class="col-span-4" v-if="customerModal.mode === 'edit'">
              <vs-button
                color="gray"
                class="w-full"
                @click="redirectCustomerRentalHistory"
                >ประวัติการเช่า/ซื้อ</vs-button
              >
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
          vs-xs="12"
          class="form-label px-2"
        >
          <div class="grid grid-cols-6 mb-6">
            <div class="col-span-2 flex justify-end items-center pr-4">
              Line ID
            </div>
            <div class="col-span-4">
              <vs-input class="w-full" v-model="modalFields.lineId" />
            </div>
            <div class="col-span-6 mt-6"></div>
            <div class="col-span-2 flex justify-end items-center pr-4">
              birthdate
            </div>
            <div class="col-span-4">
              <v-date-picker
                v-model="modalFields.birthDate"
                :locale="{
                  id: 'th',
                  firstDayOfWeek: 2,
                  masks: { L: 'DD/MM/YYYY' }
                }"
              />
            </div>
            <div class="col-span-6 mt-6"></div>
            <div class="col-span-2 flex justify-end items-center pr-4">
              รู้จักได้ยังไง
            </div>
            <div class="col-span-4">
              <select
                v-model="modalFields.howToKnowShop"
                class="w-full px-2 py-3 rounded"
                style="border: 1px solid #e0e0e0"
              >
                <option value="">-- กรุณาเลือก --</option>
                <option value="1">เพื่อนแนะนำ</option>
                <option value="2">Google</option>
                <option value="3">Facebook</option>
              </select>
            </div>
            <div class="col-span-6 mt-6"></div>
            <div
              class="col-span-2 flex justify-end items-center pr-4"
              v-if="customerModal.mode === 'edit'"
            >
              วันที่สมัคร
            </div>
            <div class="col-span-4" v-if="customerModal.mode === 'edit'">
              {{ modalFields.createDateTime }}
            </div>
            <div class="col-span-6 mt-6"></div>
            <div
              class="col-span-2 flex justify-end items-center pr-4"
              v-if="customerModal.mode === 'edit'"
            >
              มูลค่า Voucher
            </div>
            <div class="col-span-4" v-if="customerModal.mode === 'edit'">
              <span
                class="text-black text-xl"
                v-text="modalFields.totalVouchers"
                >0</span
              ><br />
            </div>
            <div class="col-span-6 mt-4"></div>
            <div class="col-span-2 flex justify-end items-center pr-4"></div>
            <div class="col-span-4" v-if="customerModal.mode === 'edit'">
              <span class="text-danger"
                >Expired {{ modalFields.voucherExpire }}</span
              >
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-button
            @click="confirmModal"
            style="margin:10px;"
            color="success"
            type="filled"
          >
            ตกลง
          </vs-button>
          <vs-button
            @click="closeCustomerModal"
            style="margin:10px;"
            color="danger"
            type="filled"
          >
            ยกเลิก
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- end modal -->
    <div class="px-4 py-8">
      <div class="flex justify-between mb-8">
        <div>
          <h3>ข้อมูลลูกค้า</h3>
        </div>
        <div>
          <vs-button color="success" @click="openModal()">
            <font-awesome-icon icon="plus" />
            เพิ่มข้อมูล
          </vs-button>
        </div>
      </div>
      <vs-table pagination max-items="20" search class="mt-8" :data="customers">
        <template slot="thead">
          <!-- <vs-th sort-key="code" class="whitespace-no-wrap">รหัส</vs-th> -->
          <vs-th sort-key="name" class="whitespace-no-wrap">ชื่อลูกค้า</vs-th>
          <vs-th>ที่อยู่</vs-th>
          <vs-th sort-key="phone" class="whitespace-no-wrap">เบอร์ติดต่อ</vs-th>
          <vs-th sort-key="lastOrder" class="whitespace-no-wrap"
            >คำสั่งซื้อ</vs-th
          >
          <vs-th sort-key="updateBy" class="whitespace-no-wrap"
            >อัพเดตล่าสุด</vs-th
          >
          <vs-th>
            <div class="flex justify-center w-full whitespace-no-wrap">
              การจัดการ
            </div>
          </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="index" v-for="(tr, index) in data">
            <!-- <vs-td>{{ tr.code }}</vs-td> -->
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.address }}</vs-td>
            <vs-td>{{ tr.phone }}</vs-td>
            <vs-td>  <vs-button
                color="gray"
                class="w-full"
                @click="redirectCustomerRentalHistoryObject(tr)"
                >ประวัติการเช่า/ซื้อ</vs-button></vs-td>
            <vs-td>
              {{ tr.updateBy }}
              <br />
              {{ tr.updateDateTime }}
            </vs-td>
            <vs-td>
              <div class="flex justify-between">
                <font-awesome-icon
                  @click="openModal(index, tr)"
                  icon="edit"
                  style="color: #5A8EE6"
                  size="lg"
                  class="cursor-pointer"
                />
                <font-awesome-icon
                  @click="removeCustomer(tr)"
                  icon="trash"
                  class="text-danger cursor-pointer"
                  size="lg"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vs-card>
</template>

<script>
export default {
  data() {
    return {
      customerModal: {
        active: false,
        label: "",
        mode: ""
      },
      users: [
        {
          id: 1,
          code: "S003359",
          name: "alex app 1",
          address: "bangkok",
          phoneNumber: "0912345678",
          lastOrder: "OR12103230001",
          lastUpdate: "2021-03-23",
          updateBy: "support@alexrental.app",
          birthdate: "2021-01-01"
        },
        {
          id: 2,
          code: "S003360",
          name: "alex app 2",
          address: "bangkok",
          phoneNumber: "0912345678",
          lastOrder: "OR12103230001",
          lastUpdate: "2021-03-23",
          updateBy: "support@alexrental.app",
          birthdate: "2021-01-01"
        },
        {
          id: 3,
          code: "S003361",
          name: "alex app 3",
          address: "bangkok",
          phoneNumber: "0912345678",
          lastOrder: "OR12103230001",
          lastUpdate: "2021-03-23",
          updateBy: "support@alexrental.app",
          birthdate: "2021-01-01"
        }
      ],
      customers: [],
      optionKnowSelected: 1,
      optionKnow: [
        { text: "Option A", value: 1 },
        { text: "Option B", value: 2 },
        { text: "Option C", value: 3 }
      ],
      modalFields: {
        customerId: "",
        code: "",
        name: "",
        description: null,
        address: "",
        phone: "",
        accountNumber: "",
        accountName: "",
        bankName: "",
        state: true,
        createDateTime: "",
        lastOrder: "-",
        lineId: "",
        birthDate: "",
        howToKnowShop: "",
        createBy: "",
        updateDateTime: "",
        updateBy: "",
        shopInfoId: "",
        shopInfo: null,
        totalPoints: "",
        totalVouchers: "",
        voucherExpire: ""
      }
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearModalFields() {
      this.modalFields = {
        customerId: "",
        code: "",
        name: "",
        description: null,
        address: "",
        phone: "",
        accountNumber: "",
        accountName: "",
        bankName: "",
        state: true,
        createDateTime: "",
        lastOrder: "-",
        lineId: "",
        birthDate: "",
        howToKnowShop: "",
        createBy: "",
        updateDateTime: "",
        updateBy: "",
        shopInfoId: "",
        shopInfo: null,
        totalPoints: "",
        totalVouchers: "",
        voucherExpire: ""
      };
    },
    openModal(index, tr) {
      if (index === undefined && tr === undefined) {
        // create
        this.clearModalFields();
        this.customerModal = {
          active: true,
          label: "เพิ่มข้อมูล",
          mode: "create"
        };
      } else {
        // edit
        this.clearModalFields();
        tr.birthDate = new Date(tr.birthDate);
        this.modalFields = tr;
        this.getCustomerPoint(tr);
      }
    },
    closeCustomerModal() {
      this.clearModalFields();
      this.fetchCustomer();
      this.customerModal.active = false;
    },
    getCustomerPoint(tr) {
      console.log(">>> getCustomerPoint");
      const state = this.$store.state;
      const request = this.$customAxios(
        "GET",
        `${state.apiURL}/api/v2/GetCustomerPointVoucher/${tr.customerId}`,
        {},
        state.auth.apiHeader
      );

      request.then(res => {
        this.modalFields.totalPoints = this.formatPrice(res.totalPoints);
        this.modalFields.totalVouchers = this.formatPrice(res.totalVouchers);
        this.modalFields.voucherExpire = this.$dateFormat(res.voucherExpire);
        this.customerModal = {
          active: true,
          label: "แก้ไขข้อมูล",
          mode: "edit"
        };
      });
    },
    confirmModal() {
      console.log(`>>> confirmModal`);
      let url = "";
      let createDateTime = "";
      const state = this.$store.state;

      if (this.customerModal.mode === "create") {
        // create
        url = `${state.apiURL}/api/customer`;
        createDateTime = new Date().toISOString();
      } else {
        // edit
        url = `${state.apiURL}/api/v2/UpdateCustomer`;
        createDateTime = this.modalFields.createDateTime;
      }

      this.customerModal.createDateTime = createDateTime;
      this.customerModal.updateDateTime = this.$dateFormat(
        new Date().toISOString()
      );

      const request = this.$customAxios(
        "POST",
        url,
        this.modalFields,
        state.auth.apiHeader
      );

      request.then(res => {
        console.log(`>>> res confirm modal`);
        console.log(res);
        if (this.customerModal.mode === "create") {
          this.$swal("เพิ่มมูลสำเร็จ", "", "success");
        } else {
          this.$swal("แก้ไขข้อมูลสำเร็จ", "", "success");
        }
        this.closeCustomerModal();
      });
    },
    redirectCustomerRentalHistoryObject(customer) {
      // this.$router.push({
      //   name: "customer-rental-history",
      //   params: { customerId: this.modalFields.customerId }
      // });
      window.open("/backoffice/customer-rental-history/" + customer.customerId, "_blank");
      // this.customerModal.active = false;
    },
    redirectCustomerRentalHistory() {
      // this.$router.push({
      //   name: "customer-rental-history",
      //   params: { customerId: this.modalFields.customerId }
      // });
      window.open("/backoffice/customer-rental-history/" + this.modalFields.customerId, "_blank");
      // this.customerModal.active = false;
    },
    removeCustomer(tr) {
      console.log(`>>> remove customer`);
      this.$swal({
        title: "ยืนยันการลบ",
        html: "",
        icon: "warning",
        showCancelButton: true
      }).then(confirm => {
        if (confirm.value) {
          this.confirmRemoveCustomer(tr);
        }
      });
    },
    confirmRemoveCustomer(tr) {
      console.log(`>>> confirm remove customer`);
      const state = this.$store.state;
      const request = this.$customAxios(
        "DELETE",
        `${state.apiURL}/api/customer/${tr.customerId}`,
        {},
        state.auth.apiHeader
      );

      request.then(() => {
        this.$swal("ลบข้อมูลสำเร็จ", "", "success");
        this.closeCustomerModal();
      });
    },
    fetchCustomer() {
      console.log(`>>> fetch customer`);
      const state = this.$store.state;
      const request = this.$customAxios(
        "GET",
        `${state.apiURL}/api/customer`,
        {},
        state.auth.apiHeader
      );

      request.then(res => {
        res.forEach((item, index) => {
          res[index].createDateTime = this.$dateFormat(item.createDateTime);
          res[index].updateDateTime = this.$dateFormat(item.updateDateTime);
        });
        this.customers = res;
      });
    }
  },
  mounted() {
    console.log(`>>> customer mounted`);
    this.fetchCustomer();
  }
};
</script>

<style>
.con-vs-popup .vs-popup {
  width: 100% !important;
}

.vs-popup {
  width: 100% !important;
}
</style>
